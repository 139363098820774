import React from 'react';
import PropTypes from 'prop-types';

import './Place.scss';

import PlaceMark from '../shared/icons/mark.svg';

const Place = ({name}) => (
  <div className="place">
    <div className="place__mark"><PlaceMark /></div>
    <div className="place__name">{name}</div>
  </div>
);

Place.propTypes = {
  name: PropTypes.string.isRequired
};

export default Place;
