import React from 'react';
import {StaticQuery, graphql} from 'gatsby';

import './CareerList.scss';
import CareerBox from './CareerBox';

const CareerList = ({data}) => (
    <div className="career-list">{getCareerList(data)}</div>
);

function getCareerList(data) {
  const careerListArray = [];
  data.allCareerListJson.edges.forEach(item =>
    careerListArray.push(<CareerBox key={item.node.name} name={item.node.name} url={item.node.url}  places={item.node.places} />)
  );
  return careerListArray;
}

export default props => (
  <StaticQuery
    query={graphql`
      query CareerListQuery {
        allCareerListJson {
          edges {
            node {
              name
              url
              places {
                name
              }
            }
          }
        }
      }
    `}
    render={data => <CareerList data={data} {...props} />}
  />
);
