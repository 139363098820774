import React, {Component} from 'react';
import withLayout from '../layout';

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./my.scss";
import TextBardziej from '../components/Typography/TextBardziej';
import ContactSlide from "../components/Contact/ContactSlide";

import BardziejButton from '../components/BardziejButton';

import {FormattedHTMLMessage, injectIntl} from "react-intl";
import CareerList from '../components/Career/CareerList';
import Panorama from "../components/My/Panorama";
import ScrollableVideo from "../components/My/ScrollableVideo";
import SectionColumn from "../components/layout/Sections/SectionColumn";
import TextContent from "../components/Typography/TextContent";
import Spacer from "../components/layout/Helpers/Spacer";
import Section from "../components/layout/Sections/Section";
import Timeline from "../components/My/Timeline";

class MyPage extends Component {

  componentDidMount() {
    document.body.classList.add('painted');
  }

  componentWillUnmount() {
    // document.body.classList.remove('painted');
  }

  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              timeline: file(relativePath: { eq: "timeline.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c1: file(relativePath: { eq: "kariera/1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c2: file(relativePath: { eq: "kariera/2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c3: file(relativePath: { eq: "kariera/3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c4: file(relativePath: { eq: "kariera/4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c5: file(relativePath: { eq: "kariera/5.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c6: file(relativePath: { eq: "kariera/6.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              c7: file(relativePath: { eq: "kariera/7.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              bardziejBorder: file(relativePath: { eq: "bardziej-border.jpg" }) {
                childImageSharp {
                  fixed(width: 330, height: 462) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
            }
          `}
          render={data => (
            <div className="page page--static page--my">

              <Panorama />

              <Section modifiers={['text', 'columns', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['light-big']}>
                    <h1>
                      <FormattedHTMLMessage id="my.text1" />
                    </h1>
                  </TextContent>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="my.text2" />
                  </TextContent>
                  <Spacer />
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id="my.text3" />
                  </TextContent>
                  <Spacer modifiers={['small']} />
                  <TextContent modifiers={['normal', 'bold']}>
                    <strong>
                      <FormattedHTMLMessage id="my.text4" />
                    </strong>
                  </TextContent>
                </SectionColumn>
              </Section>

              <Section modifiers={['2', 'padding-x', 'padding-y-small']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['normal', 'center']}>
                    <ScrollableVideo video='/video/dron_zoom.mp4'/>
                  </TextContent>
                </SectionColumn>
              </Section>

              <Section modifiers={['text-bardziej', 'columns', 'padding-x']} modifiersLayout={['padding', 'top']}>
                <SectionColumn>
                  <TextBardziej>
                    <h2>
                      <strong>
                        <FormattedHTMLMessage id="my.text5" />
                      </strong>
                    </h2>
                  </TextBardziej>
                </SectionColumn>
                <SectionColumn>
                  <TextContent modifiers={['small']}>
                    <FormattedHTMLMessage id='my.text6' />
                  </TextContent>
                </SectionColumn>
              </Section>

              {/*<Section modifiersWrapper={['wider']}>*/}
              {/*  <SectionColumn>*/}
              {/*    <Timeline/>*/}
              {/*  </SectionColumn>*/}
              {/*</Section>*/}

              <Section modifiers={['5', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <div className="career-grid">
                    <div className="career-grid__row">
                      <div className="career-grid__column">
                        <TextContent modifiers={['light-big-2x']}>
                          <h2><FormattedHTMLMessage id="my.career" /></h2>
                        </TextContent>
                        <div className="career-grid__layout">
                          <Img className='image' fluid={data.c2.childImageSharp.fluid} />
                        </div>
                      </div>
                      <div className="career-grid__column">
                        <div className="career-grid__layout">
                          <Img className='image' fluid={data.c1.childImageSharp.fluid} />
                          <TextContent modifiers={['normal', 'bold']}>
                            <h3><FormattedHTMLMessage id="my.career1" /></h3>
                          </TextContent>
                          <TextContent modifiers={['small']}>
                            <FormattedHTMLMessage id="my.career2" />
                          </TextContent>
                        </div>
                      </div>
                    </div>
                    <div className="career-grid__row">
                      <div className="career-grid__column">
                        <div className="career-grid__layout">
                          <div className="career-grid__col">
                            <Img className='image' fluid={data.c4.childImageSharp.fluid} />
                          </div>
                          <div className="career-grid__col">
                            <Img className='image' fluid={data.c3.childImageSharp.fluid} />
                            <TextContent modifiers={['normal', 'bold']}>
                              <h3><FormattedHTMLMessage id="my.career3" /></h3>
                            </TextContent>
                            <TextContent modifiers={['small']}>
                              <FormattedHTMLMessage id="my.career4" />
                            </TextContent>
                          </div>
                        </div>
                        <Img className='image' fluid={data.c7.childImageSharp.fluid} />
                      </div>
                      <div className="career-grid__column">
                        <Img className='image image--1' fluid={data.c5.childImageSharp.fluid} />
                        <div className="negative-box">
                          <Img className='image image--2' fluid={data.c6.childImageSharp.fluid} />
                          <TextContent modifiers={['normal', 'bold']}>
                            <h3><FormattedHTMLMessage id="my.career5" /></h3>
                          </TextContent>
                          <TextContent modifiers={['small']}>
                            <FormattedHTMLMessage id="my.career6" />
                          </TextContent>
                        </div>
                      </div>
                    </div>
                  </div>
                </SectionColumn>
              </Section>

              <Section modifiers={['6', 'columns', 'padding-x']} modifiersLayout={['padding', 'top']}>
                <SectionColumn>
                  <TextContent modifiers={['bardziej', 'bigger-2', 'bold', 'right']}>
                    <div className="bardziej bardziej--border">
                      <Img fixed={data.bardziejBorder.childImageSharp.fixed} />
                    </div>
                    <h2><FormattedHTMLMessage id="my.offers" /></h2>
                  </TextContent>
                  <TextContent modifiers={['small', 'right']}>
                    <FormattedHTMLMessage id="my.offerinfo" />
                  </TextContent>
                  <BardziejButton hrefext="mailto:praca@intellect.pl" title="my.writetous">
                    praca@intellect.pl
                  </BardziejButton>
                </SectionColumn>
                <SectionColumn>
                  <CareerList />
                </SectionColumn>
              </Section>

            </div>
          )}
        />
        <ContactSlide standalone={true} titleTag="h2" />
      </>
    )
  }
};

const customProps = {
  localeKey: 'my'
};

export default withLayout(customProps)(injectIntl(MyPage));
