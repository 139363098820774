import Img from "gatsby-image";
import React, {Component} from "react";
import {graphql, StaticQuery} from "gatsby";

import './Panorama.scss';

class Panorama extends Component {

	constructor(props) {
		super(props);

		this.state = {
			move: -220,
			moveMode: false,
			dragStart: null,
			animated: false,
			currentSlide: 2,
		};

		this.slideWidthPercentage = 70;
	}

	componentDidMount() {
		document.querySelector('.panorama').addEventListener('mousedown', this.handlePanoramaMoveStart);
		document.querySelector('.panorama').addEventListener('mousemove', this.handlePanoramaMove);
		document.querySelector('.panorama').addEventListener('mouseup', this.handlePanoramaMoveStop);

		document.querySelector('.panorama').addEventListener('touchstart', this.handlePanoramaMoveStart);
		document.querySelector('.panorama').addEventListener('touchmove', this.handlePanoramaMove);
		document.querySelector('.panorama').addEventListener('touchend', this.handlePanoramaMoveStop);
	}

	componentWillUnmount() {
		document.querySelector('.panorama').removeEventListener('mousedown', this.handlePanoramaMoveStart);
		document.querySelector('.panorama').removeEventListener('mousemove', this.handlePanoramaMove);
		document.querySelector('.panorama').removeEventListener('mouseup', this.handlePanoramaMoveStop);

		document.querySelector('.panorama').removeEventListener('touchstart', this.handlePanoramaMoveStart);
		document.querySelector('.panorama').removeEventListener('touchmove', this.handlePanoramaMove);
		document.querySelector('.panorama').removeEventListener('touchend', this.handlePanoramaMoveStop);
	}

	handlePanoramaMoveStart = (event) => {
		// event.preventDefault();

		if (event.clientX || event.touches[0]) {
			this.setState({
				moveMode: true,
				dragStart: event.clientX ? event.clientX : event.touches[0].clientX
			});
		}
	};

	handlePanoramaMove = (event) => {
		// event.preventDefault();

		if (!event.clientX && !event.touches) {
			return false;
		}

		const moveScale = this.state.moveMode ? -1 : 0.03;

		const clientX = event.clientX ? event.clientX : event.touches[0].clientX;
		const currentMovePercentage = ((this.state.dragStart - clientX) * moveScale) / window.innerWidth * 100;

		if (this.state.dragStart !== null) {
			this.panoramaMove(this.state.move + currentMovePercentage);
		}

		this.setState({
			dragStart: clientX,
		});
	};

	getPanoramaWidthPercentage(){
		if (window.matchMedia("(max-width: 390px)").matches) {
			return 550;
		}

		if (window.matchMedia("(max-width: 640px)").matches) {
			return 450;
		}

		if (window.matchMedia("(max-width: 768px)").matches) {
			return 300;
		}

		if (window.matchMedia("(max-width: 1024px)").matches) {
			return 250;
		}

		return 200;
	}

	panoramaMove(move, animated) {
		let elements = this.state.elements;

		if (this.state.animated === true) {
			return false;
		}

		if (animated) {
			document.querySelector('.panorama__items').classList.add('panorama__items--animated');

			this.setState({
				move,
				animated: true,
			});
		}

		setTimeout(() => {
			document.querySelector('.panorama__items').classList.remove('panorama__items--animated');

			const panoramaWidth = this.getPanoramaWidthPercentage();

			if (move < -panoramaWidth - 50) {
				move += panoramaWidth;
			}

			if (move >= -50) {
				move -= panoramaWidth;
			}

			this.setState({
				move,
				elements,
				currentSlide: this.getCurrentVisibleSlide(move) - 1,
				animated: false,
			});
		}, animated ? 400 : 0);
	}

	handlePanoramaMoveStop = (event) => {
		// event.preventDefault();

		this.setState({
			moveMode: false,
			dragStart: null,
		});
	};

	getCurrentVisibleSlide(move) {
		if (!move) {
			move = this.state.move;
		}

		return Math.round(Math.abs((move - 15) / this.slideWidthPercentage) + 1);
	}

	getSlideMove(slideNumber) {
		return -((slideNumber - 1) * this.slideWidthPercentage - 15);
	}

	getNextSlide = () => {
		this.panoramaMove(this.state.move - 50, true);
	};

	getPrevSlide = () => {
		this.panoramaMove(this.state.move + 50, true);
	};

	renderPanoramaItem(data) {
		const departments = [
			{
				name: 'Office Management',
				width: 6,
			},
			{
				name: 'Wojtek i Krzysiek',
				width: 7,
			},
			{
				name: 'Client Service',
				width: 14,
			},
			{
				name: 'Creative Brain',
				width: 15,
			},
			{
				name: 'New Business',
				width: 10,
			},
			{
				name: 'Creative Brain',
				width: 9,
			},
			{
				name: 'Tech Brain',
				width: 39,
			},
		];

		return (
			<div className="panorama__item">
				<Img className='image' loading="eager" fluid={data.team.childImageSharp.fluid}/>
				<div className="panorama__departments">
					{departments.map((department, key) => (
						<div className="panorama__department" style={{width: `${department.width}%`}} key={key}>
							<div className="panorama__description">
								<span>{department.name}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	render() {
		const {move} = this.state;

		return (
			<StaticQuery
				query={graphql`
			      query {
		             team: file(relativePath: { eq: "my/team.jpg" }) {
		                childImageSharp {
		                  fluid(maxHeight: 976, quality: 100, srcSetBreakpoints: [1024, 1366]) {
		                    ...GatsbyImageSharpFluid
		                  }
		                }
		             },
			      }
			    `}
				render={(data) => (
					<div className="panorama">
						<button className="panorama__nav panorama__nav--prev" onClick={this.getPrevSlide}/>
						<button className="panorama__nav panorama__nav--next" onClick={this.getNextSlide}/>
						<div className="panorama__title">
							Wspólnie rozumiemy bardziej.
						</div>
						<div className="panorama__items" style={{transform: `translateX(${move}%)`}}>
							{this.renderPanoramaItem(data)}
							{this.renderPanoramaItem(data)}
						</div>
					</div>
				)}
			/>
		);
	}
}

export default Panorama;