import React from 'react';
import PropTypes from 'prop-types';

import Place from '../Place';
import Link from '../../components/Link';

import './CareerBox.scss';
import BardziejIcon from '../../shared/icons/bardziej.svg';

const CareerBox = ({name, url, places}) => (
  <Link to={`career/${url}`} className="career-box">
    <h3 className="career-box__name">{name}</h3>
    <div className="career-box__places">{getPlacesList(places)}</div>
    <div className="career-box__icon">
      <BardziejIcon />
    </div>
  </Link>
);


function getPlacesList(places) {
  const placesListArray = [];
  places.forEach(place =>
    placesListArray.push(<Place key={place.name} name={place.name} />)
  );
  return placesListArray;
}

CareerBox.propTypes = {
  name: PropTypes.string.isRequired,
  places: PropTypes.array.isRequired
};
export default CareerBox;
